/* tailwind is imported directly into the pages that need its styling */
/* @tailwind base;
@tailwind components;
@tailwind utilities; */

:root {
  --mainBackground: #0f162a;
  --textWhite: #ffffff;
  --fontFamily: Lato, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  --package-3-linear: linear-gradient(77deg, #7944eb -20.88%, #7bd3f3 105.73%);
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

#landing-page .calendly-overlay .calendly-popup {
  max-height: 100vh !important;
}

#landing-page.App {
  background-color: var(--mainBackground) !important;
  color: var(--textWhite) !important;
  font-family: var(--fontFamily);
}

.object-position {
  object-position: 78%;
}

.cardProject:hover .arrow,
.cardProject:hover .imgProject {
  transform: scale(1.2);
}
.cardProject .arrow,
.cardProject:hover .imgProject {
  transition: all 0.3s ease-in-out;
}

.borderBS {
  border-bottom: 0.77px solid rgba(245, 245, 245, 0.574);
  width: 300px;
  display: flex;
  justify-content: center;
}

.bg-side-arrow {
  background-image: url('/images/landing/ic_twotone-double-arrow.png');
  width: 100%;
  background-repeat: no-repeat;
  background-position: right;
  background-size: 2%;
  height: 100%;
  position: absolute;
  left: 30%;
  transform: translate(-40%, -50%);
  mix-blend-mode: screen;
  margin: 0 auto;
  z-index: -10;
}
.oneArrow {
  top: 85%;
  opacity: 0.8;
}
.twoArrow {
  top: 90%;
  opacity: 0.6;
}
.threeArrow {
  top: 95%;
  opacity: 0.4;
}

/* BACKGROUNDS  */
#landing-page .header {
  background-color: var(--mainBackground);
}
.valuesLine {
  background-image: url('/images/landing/Values-line.svg');
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 140%;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  mix-blend-mode: screen;
  margin: 0 auto;
  z-index: 1;
}
.bg-wave {
  background-image: url('/images/landing/Values-line.svg');
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
}
.bg-wave-bottom {
  background-image: url('/images/landing/Scientist-line.svg');
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100%;
  position: absolute;
  top: 78%;
  left: 50%;
  transform: translate(-50%, -50%);
  mix-blend-mode: screen;
  margin: 0 auto;
  z-index: 1;
}
.bg-waveTeam {
  background-image: url('/images/landing/Team-line.svg');
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  mix-blend-mode: screen;
  margin: 0 auto;
  z-index: 1;
}
.bg-wave-blue {
  background-image: url('/images/landing/bg-wave-5.png');
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  position: relative;
}
.bg-side {
  background-image: url('/images/landing/main-sci-shape-cut1.svg');
  width: 100%;
  background-repeat: no-repeat;
  background-size: 4%;
  height: 100%;
  position: relative;
}

.bg-side-right {
  background-image: url('/images/landing/main-sci-shape-cut2.svg');
  width: 100%;
  background-repeat: no-repeat;
  background-position: right;
  background-size: 13%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  mix-blend-mode: screen;
  margin: 0 auto;
}

.bg-position {
  background-image: url('/images/landing/main-sci-shape.svg');
  background-size: 58vw;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  padding: 1400px;
  opacity: 0.82;
  z-index: 0;
}

.bg-arrows-down {
  background-image: url('/images/landing/arrows-down.png');
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -150px;
  right: 15%;
  height: 440px;
  transform: scale(0.6);
  width: 8%;
}

.bg-arrows-down-team {
  background-image: url('/images/landing/arrows-down.png');
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: -180px;
  right: 15%;
  height: 440px;
  transform: scale(0.6);
  width: 10%;
}

.bg-position-login {
  background-image: url('/images/landing/main-sci-shape.svg');
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
  top: 22%;
  position: relative;
  /* position: absolute; */
  /* z-index: 1; */
  /* top: -1;
  left: 0;
  right: 0;
  margin: 0 auto; */
  /* position: absolute; */
  /* top: 0%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  transform: translateY(-34%);
  height: 1700px;
  /* padding: 100px 200px; */
}

.bg-positionFooter {
  width: 100%;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 1) 100%),
    url('/images/landing/main-sci-shape-purple.svg');
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(0, 90px);
  mix-blend-mode: screen;
  margin: 0 auto;
  height: 720px;
  z-index: 1;
}
.bg-positionFooterO {
  opacity: 0.10000000149011612;
  background: var(--a-le-gradiento, linear-gradient(48deg, #447deb 0%, #7bd3f3 100%));
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(0, 0);
  mix-blend-mode: screen;
  margin: 0 auto;
  height: 380px;
  z-index: 2;
}

.z-10 {
  z-index: 10;
  opacity: 1;
  position: relative;
}

#landing-page .linear {
  background: var(
    --final-gradient-v-3,
    linear-gradient(
      178deg,
      #352775 0%,
      #274b75 0.01%,
      #18597a 21.35%,
      #4481db 59.9%,
      #44dbd2 96.55%,
      #2196f3 100%
    )
  );
}
#landing-page .wd {
  width: 262.222px;
}
.linear-a {
  width: 262.222px;
  height: 67.778px;
  flex-shrink: 0;
  border-top-right-radius: 33.333px;
  border-top-left-radius: 33.333px;
  z-index: -6;
  margin: 0px -24px;
}
.linear-m {
  height: 67.778px;
  flex-shrink: 0;
  border-top-right-radius: 33.333px;
  border-top-left-radius: 33.333px;
  width: 100%;
  /* z-index: -6; */
  /* margin: 0px -24px; */
}
.linear-a1 {
  background: linear-gradient(48deg, #44c3eb 0%, #7bd3f3 100%);
}
.linear-a2 {
  background: linear-gradient(48deg, #447deb 0%, #7bd3f3 100%);
}
.linear-a3 {
  background: linear-gradient(48deg, #7944eb 0%, #7bd3f3 100%);
}
.linear-a4 {
  background: linear-gradient(48deg, #c724e1 0%, #7bd3f3 100%);
}
.rectangle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  top: 67px;
  /* left: 50%; */
  /* z-index: -10; */
}
.rectangle1 {
  border-top: 14px solid #5fcbef;
}
.rectangle2 {
  border-top: 14px solid #61aaef;
}
.rectangle3 {
  border-top: 14px solid #7a89ef;
}
.rectangle4 {
  border-top: 14px solid #a279ea;
}
.bg-position-values {
  background-image: url('/images/landing/main-sci-shape.svg');
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  mix-blend-mode: normal;
  margin: 0 auto;
  z-index: 0;
  height: 340px;
}
/* BACKGROUNDS END */
/* BUTTONS INTRO CONTAINER */
.buttonGetStarted {
  display: flex;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.15) !important;
}
.buttonGetStarted:hover {
  background: rgba(255, 255, 255, 0.341) !important;
}
.buttonHover {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  padding: 14px 24px;
  box-shadow: none;
}
.buttonHover:hover {
  -webkit-box-shadow: inset 0px 0px 0px 1px rgba(251, 249, 249, 0.479);
  -moz-box-shadow: inset 0px 0px 0px 1px rgba(253, 250, 250, 0.423);
  box-shadow: inset 0px 0px 0px 1px rgba(241, 239, 239, 0.438);
}
/* BUTTONS INTRO CONTAINER END*/
/* TITLES */
.sliderTitle {
  font-size: 4.25rem;
  font-family: Lato;
}
.sliderSubTitle {
  font-size: 1.5rem;
  font-family: Lato;
}

#landing-page .title {
  color: #fff;
  font-family: Lato;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 30.8px */
  letter-spacing: 38.92px;
}
#landing-page .subtitle {
  font-size: 1.5rem;
  letter-spacing: 24px;
}
.titleTeamSection {
  color: #fff;
  font-family: Lato;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 17.6px */
  letter-spacing: 14.72px;
  margin-top: 40px;
}

.titleTeamBorder {
  margin-top: 8px;
  width: 26px;
  height: 2px;
  background: #3578c2;
}
.footerText {
  text-align: center;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
}

.linearBorderTitle {
  margin: 10px 32px 0px 0;
  background: linear-gradient(
    270deg,
    #231b42 -0.56%,
    #b959a9 4.68%,
    #6854e0 30.87%,
    #2bb1db 63.34%,
    #4f4087 79.57%,
    #aeaf63 95.81%,
    #231b42 100%
  );
  animation: gradientMove 3s linear infinite;
  background-size: 200% 200%;
}

@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* LINEAR BORDER END */

/* TEAM SECTION */
#landing-page .text {
  text-align: justify;
  color: #ffffff;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}
.readmore-text {
  text-align: justify;
  color: #bcdbff;
  border-bottom-color: #bcdbff;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
}
.team-logo {
  width: 132px;
  height: 132px;
}
.textRole {
  font-size: 13px;
  opacity: 70%;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.textName {
  color: #fff;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 125% */
  letter-spacing: 1px;
  text-transform: uppercase;
}
.team-list {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
}
.team-list > * {
  margin-top: 2px;
}
/* TEAM SECTION END*/
/* Styling for the modal overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.688);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.video-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.688);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.video-close-button {
  position: fixed;
  top: 12%;
  right: 10%;
}
/* Styling for the modal content */
.modal-content {
  width: 100%;
  background: linear-gradient(
    135deg,
    rgba(10, 23, 68, 0.95) 0%,
    rgba(50, 82, 122, 0.95) 35.42%,
    rgba(57, 75, 125, 0.95) 67.71%,
    rgba(63, 69, 128, 0.95) 100%
  );
  background-size: 200% 200%;

  -webkit-animation: Animation 5s ease infinite;
  -moz-animation: Animation 5s ease infinite;
  animation: Animation 5s ease infinite;
  opacity: 1;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  position: relative;
}
@-webkit-keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@-moz-keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@keyframes Animation {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

/* Styling for the close button */
.close-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  position: relative;
  transform: translate(-4px, -6px);
}
.modal-button-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: -30px;
}
.modal-close-button {
  background: none;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.card-data {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
/* WIDGETS */
.custom-container {
  min-height: 570px;
  margin-left: auto;
  margin-right: auto;
  max-width: 20rem;
  text-align: center;
  color: #333;
  border-radius: 40px;
  background-color: #fff;
  z-index: 10;
}
.cardTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 14px;
}
.cardPackageTitle {
  color: #fff;
  font-family: Inter;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.087px;
}
.cardPackageSubTitle {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0.1px;
}
.package-service-fee {
  color: #285d98;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.package-offers {
  font-weight: 300;
  font-family: Inter, sans-serif;
  font-size: 14px;
  letter-spacing: 0.1px;
}
.package-price-tag {
  color: #285d98;
  font-family: Inter, sans-serif;
  font-size: 50px;
  font-weight: 900;
}
.has-hover-card {
  position: relative;
}
.hover-card {
  display: none;
  position: relative;
  bottom: 10px;
  color: white;
  padding: 13px 30px;
  border: none;
  margin-top: 5px;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 30px;
  background: var(--package-3-linear);
  box-shadow: 5px 5px 15px 10px rgba(0, 0, 0, 0.05);
  color: #fff;
  text-align: center;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 15.4px */
  letter-spacing: 0.7px;
  text-transform: uppercase;
}

.has-hover-card:hover .hover-card {
  display: inline-block;
}

.teamPerson {
  width: 200px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}
.gridValues {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 5rem;
}

.swiper-pagination {
  margin-top: -100px !important;
  padding-top: -100px !important;
  bottom: 0px !important;
  z-index: 1000;
}

.swiper-pagination-bullet {
  height: 18px !important;
  width: 18px !important;
  margin: 0px 10px !important;
}

/* pagination bullets */
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  background-color: transparent;
  border: 3px solid white;
  position: relative;
  transform: translateX(-38px);
}

.mySwiper {
  padding: 0px 0px 100px 0px !important;
}

.landing-gradient-wrapper {
  height: 15vw;
  background: linear-gradient(-45deg, #11214b, #4983de, #11214b, #329ecd);
  background-size: 400% 400%;
  animation: gradient 8s ease infinite;
  opacity: 0.9;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.landing-gradient-wrapper div {
  color: #fff;
  font-family: Lato;
  font-size: 2.6rem;
  font-style: normal;
  font-weight: 100;
  line-height: 110%;
  letter-spacing: 30px;
  opacity: 0.8;
}

.landing-gradient-wrapper img {
  width: 58vw;
  margin: auto;
  left: 50%;
  top: 90%;
  transform: translate(-50%, -50%);
  opacity: 0.9;
}

@supports (-webkit-appearance: none) {
  /* Safari-specific CSS rules */
  .blur-safari {
    -webkit-backdrop-filter: blur(20px); /* Fixes blur for Safari*/
    backdrop-filter: blur(20px); /* This line activates blur*/
    -webkit-backdrop-filter: blur(20px); /* Add this line first, it fixes blur for Safari*/
    backdrop-filter: blur(20px); /* This line activates blur*/
    z-index: 9999;
  }
}

.sci-nav-item {
  color: #e2e9ff;
  transition: transform 0.3s ease;
}
.sci-nav-item:hover {
  color: white;
  transform: scale(1.1);
}

/* MEDIA SCREEN */
@media screen and (max-width: 2000px) {
  .bg-position {
    background-size: 65vw;
    top: 38%;
  }
}

@media screen and (max-width: 1900px) {
  .landing-gradient-wrapper img {
    width: 70vw;
  }
  .valuesLine {
    height: 120%;
    top: 50%;
  }
  .bg-wave-bottom {
    top: 72%;
  }
}

@media screen and (max-width: 1620px) {
  .landing-gradient-wrapper {
    height: 25vh;
  }
  .bg-arrows-down,
  .bg-arrows-down-team {
    right: 10%;
  }
}

@media screen and (max-width: 1320px) {
  .landing-gradient-wrapper {
    height: 25vh;
  }
  .bg-arrows-down,
  .bg-arrows-down-team {
    right: 5%;
  }
}

@media screen and (max-width: 1000px) {
  .mySwiper {
    padding: 0px 0px 60px 0px !important;
  }

  .landing-gradient-wrapper {
    height: 20vh;
  }

  .landing-gradient-wrapper div {
    font-size: 1.6rem;
    letter-spacing: 28px;
  }

  .bg-arrows-down {
    bottom: -80px;
    right: 80px;
    transform: scale(0.5);
  }
  .bg-arrows-down-team {
    background-image: url('/images/landing/arrows-down.png');
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: -130px;
    right: 80px;
    height: 440px;
    transform: scale(0.5);
    width: 10%;
  }
  .sliderTitle {
    font-size: 3.4rem;
    color: var(--White--High-Emphasis, #fff);
    font-feature-settings: 'liga' off;
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: -1.383px;
  }
  .sliderSubTitle {
    font-size: 1.25rem;
  }
  #landing-page .title {
    font-size: 26px;
  }
  #landing-page .subtitle {
    font-size: 1.375rem;
  }
  .gridValues {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    column-gap: 5rem;
  }
}
@media screen and (max-width: 768px) {
  .landing-gradient-wrapper {
    height: 23vh;
  }

  .landing-gradient-wrapper div {
    font-size: 1.4rem;
    letter-spacing: 26px;
  }

  .landing-gradient-wrapper img {
    width: 100vw;
  }

  .valuesLine {
    background-size: 300%;
    background-position: left;
  }
  .bg-wave-bottom {
    background-size: 200%;
  }
  .bg-wave-blue {
    background-size: 200%;
  }
  .bg-wave {
    background-size: 600%;
  }
  .bg-positionFooter {
    height: 760px;
  }
  .bg-positionFooterO {
    height: 412px;
  }

  .team-logo {
    width: 76px;
    height: 76px;
  }
  .textName {
    text-align: start;
    width: 100px;
    margin-left: 10px;
  }
  .textRole {
    width: 70px;
  }
  .dividerBorderTeam {
    border-left: 1px solid rgba(176, 174, 174, 0.327);
    width: 1px;
    height: 32px;
  }
  .teamPerson {
    width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }
  .team-list {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .team-list > * {
    margin-top: 2px;
  }
  .py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #447ceb21;
  }
  .py-60 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .bg-position {
    width: 100%;
    height: 60vh;
    padding: 336px;
    top: 40%;
    transform: translate(-50%, -50%);
    left: 50%;
    background-size: contain;
  }
  .sliderTitle {
    font-size: 2.6rem;
    color: var(--White--High-Emphasis, #fff);
    font-feature-settings: 'liga' off;
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    line-height: 60px; /* 108.441% */
    letter-spacing: -1.383px;
  }
  .sliderSubTitle {
    font-size: 1.125rem;
  }
  #landing-page .title {
    font-size: 26px;
  }
  #landing-page .subtitle {
    font-size: 1.375rem;
  }
  .teamPerson {
    width: 500px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
@media screen and (max-width: 700px) {
  .landing-gradient-wrapper div p span {
    display: block;
    padding-top: 1.6rem;
  }

  .landing-gradient-wrapper img {
    width: 100vw;
  }

  .teamPerson {
    width: 370px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (max-width: 630px) {
  .landing-gradient-wrapper {
    height: 24vh;
  }
}

.bg-arrows-down-team,
.bg-arrows-down {
  right: 5%;
}

@media screen and (max-width: 500px) {
  .bg-arrows-down {
    top: 70%;
  }

  .buttonGetStarted,
  .buttonHover {
    font-size: 14px !important;
  }

  #landing-page .subtitle {
    font-size: 16px;
  }
  #landing-page .titleTeamSection {
    letter-spacing: 14.72px;
    margin-top: 10px;
  }

  .video-close-button {
    top: 6%;
    right: 6%;
  }
  .teamPerson {
    width: 280px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  #landing-page .title {
    font-size: 20px;
    letter-spacing: 18.4px;
  }

  #content {
    padding: 2rem 1.5rem;
  }
  .sliderTitle {
    line-height: 40.5px !important; /* 96.429% */
  }
  .sliderSubTitle {
    font-size: 1.125rem;
  }
  .linearBorderTitle {
    margin: 10px 15px 0px 0px;
  }
  .bg-arrows-down {
    bottom: -80px;
    right: 4%;
    height: 440px;
    transform: scale(0.4);
    width: 10%;
  }

  .bg-arrows-down-team {
    top: -180px;
    right: 4%;
    height: 440px;
    transform: scale(0.4);
    width: 10%;
  }
  .swiper-pagination-bullet {
    height: 15px !important;
    width: 15px !important;
    margin: 0px 15px !important;
  }
}
@media screen and (max-width: 400px) {
  .buttonGetStarted,
  .buttonHover {
    font-size: 12px !important;
  }
  .bg-positionFooter {
    height: 750px;
  }
  .bg-positionFooterO {
    height: 430px;
  }
  .footerText {
    text-align: center;
    width: 357px;
  }
  .sliderTitle {
    font-size: 2.6rem;
    font-feature-settings: 'liga' off;
    font-family: Lato;
    font-style: normal;
    font-weight: 700;
    line-height: 40.5px !important; /* 96.429% */
    letter-spacing: -1.05px;
  }
  .sliderSubTitle {
    font-size: 1.125rem;
  }
  #landing-page .title {
    font-size: 20px;
  }
  #landing-page .subtitle {
    font-size: 1rem;
  }
  .teamPerson {
    width: 250px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
}
